<template>
	<div>
		<v-app-bar
			v-if="!$route.meta.hideNav && user?.username"
			app
			clipped-left
			flat
			outlined
			style="background-color: white; z-index: 99"
			id="navbar"
		>
			<div
				style="
					width: 85%;
					margin-inline: auto;
					display: flex;
					align-items: center;
					justify-content: space-between;
				"
				id="navbar-row"
			>
				<template v-if="$vuetify.breakpoint.width > 510">
					<v-img
						alt="Barkeep"
						class="shrink"
						contain
						src="@/assets/barkeep-large.png"
						width="100"
					/>
					<v-spacer></v-spacer>
				</template>
				<div v-if="user">
					<v-btn
						color="#13670B"
						dark
						link
						to="/stocksearch"
						id="tourStockSearchScreen"
						v-if="$route.name == 'recommendations'"
					>
						Go To Stock Search
					</v-btn>
					<v-btn
						color="#13670B"
						dark
						link
						to="/recommendations"
						v-else
					>
						Go To Barkeep
					</v-btn>
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								text
								id="tourSignout"
							>
								{{ user.name }}
							</v-btn>
						</template>
						<v-list>
							<v-list-item @click="signOut()">
								<v-list-item-title>Sign out</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
					<v-tooltip
						left
						bottom
						v-if="this.$route.path == '/recommendations'"
					>
						<template v-slot:activator="{ on }">
							<v-btn
								icon
								tile
								text
								v-on="on"
								@click="startTour()"
								id="tourHelp"
							>
								<v-icon>mdi-help-circle-outline</v-icon>
							</v-btn>
						</template>
						<span>Help</span>
					</v-tooltip>
				</div>
				<template v-if="$vuetify.breakpoint.smAndUp">
					<v-spacer></v-spacer>

					<v-img
						alt="Heineken Beverages"
						class="shrink"
						contain
						src="@/assets/heineken-beverages-logo.png"
						width="100"
					/>
				</template>
			</div>
		</v-app-bar>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { signOut } from "@/auth";

export default {
	name: "NavigationComponent",

	data: () => ({}),

	created() {},

	mounted() {},

	computed: {
		...mapGetters(["user"]),
	},

	methods: {
		signOut() {
			signOut();
			this.$router.push("login");
		},
		startTour() {
			this.$eventBus.$emit("start-tour");
		},
	},

	watch: {},
};
</script>

<style>
.driver-close-btn {
	display: none !important;
}
@media only screen and (max-width: 960px) {
	#navbar-row {
		width: 100% !important;
	}
}
@media only screen and (max-width: 510px) {
	#navbar-row {
		justify-content: center !important;
	}
}
</style>
