import Vue from "vue";
import VueRouter from "vue-router";
import RecommendationsView from "../views/RecommendationsView.vue";
import StockSearchView from "../views/StockSearchView.vue";
import LoginView from "../views/LoginView.vue";
import SignOut from "../views/SignOut.vue";

import store from "../store";

import { isAuthenticated } from "../auth";

Vue.use(VueRouter);

const routes = [
	{
		path: "*",
		redirect: "/recommendations",
	},
	{
		path: "/stocksearch",
		name: "stocksearch",
		component: StockSearchView,
	},
	{
		path: "/recommendations",
		name: "recommendations",
		component: RecommendationsView,
	},
	{
		path: "/login",
		name: "login",
		component: LoginView,
	},
	{
		path: "/signout",
		name: "signout",
		component: SignOut,
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.name === "login" && isAuthenticated())
		router.push({ name: "recommendations" });
	else if (to.name !== "login" && !isAuthenticated()) {
		store.dispatch("setUser", null);
		router.push({ name: "login" });
	} else next();
});

export default router;
