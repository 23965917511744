<template>
	<validation-observer
		ref="feedback"
		v-slot="{ reset }"
		tag="form"
		v-if="user"
	>
		<div>
			<v-layout
				column
				class="fab-container"
				v-if="$route.name != 'maintenance' && user?.username"
				id="tourFeedback"
				style="z-index: 10"
			>
				<v-tooltip left>
					<template v-slot:activator="{ on }">
						<v-btn
							color="#13670B"
							v-on="on"
							fab
							dark
							style="width: 3rem; height: 3rem"
							@click="
								reset();
								dialog = true;
							"
						>
							<v-icon>mdi-chat-question</v-icon>
						</v-btn>
					</template>
					<span>Give us feedback</span>
				</v-tooltip>
			</v-layout>

			<v-dialog
				v-model="dialog"
				no-click-animation
				retain-focus
				max-width="400px"
				:key="gKey"
				persistent
			>
				<v-card>
					<v-card-title>
						<span class="text-h5">Give Us Feedback</span>
					</v-card-title>
					<v-card-text class="pb-0">
						<v-container>
							<v-select
								v-model="feedback.feedback_type"
								:items="['General', 'Issue']"
								label="Type of Feedback"
								outlined
								dense
								hide-details
								@change="
									feedback.info = {};
									reset();
								"
							>
							</v-select>
							<div
								v-if="feedback.feedback_type == 'General'"
								class="mt-3"
							>
								<span
									class="text-button"
									style="font-size: 14px !important"
									>Ease of Use:</span
								>
								<div class="d-flex">
									<span class="ma-auto pb-6">Poor</span>
									<validation-provider
										v-slot="{ errors }"
										:rules="{
											required:
												feedback.feedback_type ==
												'General'
													? true
													: false,
										}"
									>
										<v-input
											:value="
												feedback.info['ease_of_use']
											"
											:error-messages="errors"
										>
											<v-rating
												empty-icon="mdi-star-outline"
												full-icon="mdi-star"
												half-icon="mdi-star-half-full"
												half-increments
												:ripple="false"
												hover
												size="24"
												length="5"
												v-model="
													feedback.info['ease_of_use']
												"
											></v-rating>
										</v-input>
									</validation-provider>
									<span class="ma-auto pb-6">Great</span>
								</div>
								<span class="text-button"
									>Relevant Recommendations:</span
								>
								<div class="d-flex">
									<span class="ma-auto pb-6">Poor</span>
									<validation-provider
										v-slot="{ errors }"
										:rules="{
											required:
												feedback.feedback_type ==
												'General'
													? true
													: false,
										}"
									>
										<v-input
											:value="
												feedback.info[
													'relevance_of_recommendation'
												]
											"
											:error-messages="errors"
										>
											<v-rating
												empty-icon="mdi-star-outline"
												full-icon="mdi-star"
												half-icon="mdi-star-half-full"
												half-increments
												:ripple="true"
												hover
												size="24"
												length="5"
												v-model="
													feedback.info[
														'relevance_of_recommendation'
													]
												"
											></v-rating>
										</v-input>
									</validation-provider>
									<span class="ma-auto pb-6">Great</span>
								</div>
							</div>
							<div v-else class="mt-3">
								<span class="text-button"
									>Urgency of Issue:</span
								>
								<div class="d-flex">
									<span class="ma-auto pb-6">Minor</span>
									<validation-provider
										v-slot="{ errors }"
										:rules="{
											required:
												feedback.feedback_type ==
												'General'
													? false
													: true,
										}"
									>
										<v-input
											:value="feedback.info['urgency']"
											:error-messages="errors"
										>
											<v-rating
												empty-icon="mdi-checkbox-blank-circle-outline"
												full-icon="mdi-checkbox-blank-circle"
												hover
												:ripple="false"
												size="22"
												length="5"
												v-model="
													feedback.info['urgency']
												"
											></v-rating>
										</v-input>
									</validation-provider>
									<span class="ma-auto pb-6">Critical</span>
								</div>
							</div>
							<validation-provider
								v-slot="{ errors }"
								rules="required"
							>
								<v-textarea
									class="mt-3"
									label="Feedback"
									outlined
									required
									v-model="feedback.feedback"
									:error-messages="errors"
								></v-textarea>
							</validation-provider>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="#13670B"
							dark
							@click="addFeedback()"
							:loading="loading"
							:disabled="loading"
						>
							Send
						</v-btn>
						<v-btn
							@click="
								clearFeedback();
								reset();
							"
						>
							Cancel
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-snackbar v-model="snackbar" color="success" timeout="2000">
				Feedback Sent!
				<template v-slot:action="{ attrs }">
					<v-btn icon v-bind="attrs" @click="snackbar = false">
						<v-icon>mdi-close-circle-outline</v-icon>
					</v-btn>
				</template>
			</v-snackbar>
		</div>
	</validation-observer>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { extend } from "vee-validate";

extend("required", {
	validate(value) {
		return {
			required: true,
			valid: ["", null, undefined].indexOf(value) === -1,
		};
	},
	message: () => "Field is required",
	computesRequired: true,
});

export default {
	name: "App",
	data: () => ({
		snackbar: false,
		dialog: false,
		loading: false,
		feedback: {
			feedback_type: "Issue",
			info: {},
			feedback: "",
		},
		gKey: 1,
	}),
	created() {},
	computed: {
		...mapGetters(["user"]),
	},
	methods: {
		async addFeedback() {
			let x = await this.$refs.feedback.validate();
			if (!x) return;
			this.loading = true;
			await axios
				.post(`https://barkeeprecommendations.azurewebsites.net/api/feedback`, {
					...this.feedback,
					user_email: this.user.username,
					date_added: new Date().toJSON(),
				})
				.then((res) => {
					this.snackbar = true;
					this.loading = false;
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
				});
			this.clearFeedback();
		},
		clearFeedback() {
			this.feedback = {
				feedback_type: "Issue",
				info: {},
				feedback: "",
			};
			this.gKey++;
			this.loading = false;
			this.dialog = false;
		},
	},
};
</script>

<style>
.fab-container {
	position: fixed;
	bottom: 16px;
	right: 16px;
	width: 3rem;
	height: 3rem;
}
</style>
