<template>
	<div class="d-flex justify-center align-center" id="signOut">
		<v-progress-circular
			indeterminate
			color="#13670B"
		></v-progress-circular>
	</div>
</template>

<script>
export default {
	created() {
		this.$router.push("login");
	},
};
</script>

<style scoped>
#signOut {
	min-height: 100%;
	width: 100%;
}
</style>
